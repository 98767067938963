@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
/* define colours */
:root {
  --secondary-color: #2e3c40;
  --primary-color: #e2ba5a;
  --white-color: #faf9f6;
}
* {
  font-family: "montserrat", sans-serif;
}

h1,
h2,
h3 {
  max-width: 100vw;
  overflow: hidden;
}
/* .active::after{
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 50%;
  background-color: var(--primary-color);
  transition: all 0.25s ease-in-out;
  transform: translateX(-50%);
} */

html {
  scroll-behavior: smooth;
  scrollbar-width: thin;
  overflow-x: hidden;
  background-color: var(--white-color);
  scrollbar-color: rgba(155, 155, 155, 0.7) transparent;
}

/* This is for Chrome, Safari, and Opera */
html::-webkit-scrollbar {
  width: 12px;
}

html::-webkit-scrollbar-track {
  background: transparent;
}

html::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.7);
  border-radius: 20px;
  border: 3px solid transparent;
}
body {
  background-color: var(--white-color);
  margin: 0;
  padding: 0;
  font-family: "montserrat", sans-serif;
  max-width: 100vw;
}
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  height: 60px;
}
.logo img {
  height: 68px;
}
.logo:hover {
  cursor: pointer;
  opacity: 0.8;
  transition: all 0.25s ease-in-out;
  transform: scale(1.1);
  -webkit-transform: scale(1.1); /* Add this line */
}
ul {
  list-style: none;
  padding: 0;
}

.links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  min-width: 700px;
  margin-right: 25%;
  margin-left: 1rem;
}
.links-li a {
  position: relative; /* Add this line */
  text-decoration: none;
  color: #000000;
  font-size: 1.2rem;
  font-weight: 400;
  padding: 0.5rem 1rem;
  transition: 0.2s ease-in-out;
}
.links-li a:visited {
  color: #000000;
}

.links a::before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 50%;
  background-color: #4b4b4b;
  transition: all 0.25s ease-in-out;
  transform: translateX(-50%);
}

.links a:hover {
  color: #808080;
  /* font-size: 1.3rem; */
}

.links a:hover::before {
  width: 100%;
}

.toggle-button {
  display: none;
  height: 50px;
  padding: 15px;
  width: 50px;
  flex-wrap: wrap;
  position: absolute;
  top: 16px;
  right: 16px;
}
.toggle-button:hover {
  cursor: pointer;
  opacity: 0.6;

  transition: all 0.25s ease-in-out;
}

.toggle-button .bar {
  display: block;
  width: 100%;
  height: 5px;
  border-radius: 10px;
  background-color: #000000;
  margin: 5px;
  transition: 0.4s;
  justify-content: center;
  align-items: center;
}
.isActive {
  display: block;
  flex-direction: column;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 0;
  padding-top: 50px;
  right: 0;
  background-color: var(--white-color);
  object-fit: cover;
}

.isActive .bar:nth-child(1) {
  transform: rotate(-47deg) translate(5px, 1px);
  -webkit-transform: rotate(-47deg) translate(5px, 1px); /* Add this line */
  width: 79%;
  height: 6px;
}

.isActive .bar:nth-child(3) {
  transform: rotate(45deg) translate(-10px, -15px);
  -webkit-transform: rotate(45deg) translate(-10px, -15px); /* Add this line */
  width: 80%;
  height: 6px;
}

.o-Nas {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 95vh;
  clip-path: polygon(0 0, 100% 0, 100% 92%, 0 100%);
  background: no-repeat url("../Photos/piran.webp");
  background-size: cover; /* Add this line */
}
.glass {
  background: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.418),
    rgba(180, 180, 180, 0.103)
  );
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px); /* Add this line */
  border-radius: 10px;
  padding: 1rem;
  /* width: 50%; */
  text-align: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  backdrop-filter: blur(10px);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
.naslov {
  /* background: -webkit-linear-gradient(left, rgb(43, 43, 43), rgb(102, 102, 102));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: rgb(26, 26, 26);
  /* text-decoration: underline; */
  text-decoration-thickness: 3px;
  text-underline-offset: 10px;
  width: 100%;
  font-size: 2rem;
  font-weight: 600;
  margin: 0 0 2rem 0;
}

.text {
  text-align: left;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 1.8rem;
  font-weight: 400;
  margin-bottom: 1rem;
  padding: 4rem;
  width: 85vw;
  color: rgb(255, 255, 255);
  background-color: var(--white-color);
  clip-path: polygon(0 0, 100% 8%, 100% 100%, 0 100%);
}

@keyframes jump {
  0%,
  100% {
    bottom: 3rem;
  }
  50% {
    bottom: 5.5rem;
  }
}

@keyframes jump-phone {
  0%,
  100% {
    bottom: 0rem;
  }
  50% {
    bottom: 2.5rem;
  }
}

.scroll-down-icon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 7em;
  color: var(--white-color);
  /* filter: opacity(0.3); */
  /* backdrop-filter: invert(1); */
  text-decoration: none;
  animation: jump 1.5s infinite; /* Add this line */
}

.page-contain {
  display: flex;
  flex-wrap: wrap;
  /* height: 40vh; */
  align-items: flex-start;
  justify-content: center;
  padding: 0 10% 4rem;
  font-family: "Open Sans", sans-serif;
  font-style: none;
  /* background-color: #ffd0a53a; */
}
.data-card {
  display: flex;
  flex-direction: column;
  width: 10vw;
  min-width: 200px;
  min-height: 300px;
  overflow: hidden;
  text-decoration: none;
  background: rgba(219, 218, 218, 0.598);
  margin: 1vw;
  position: relative;
  padding: 2.75em 1.5em;
  clip-path: polygon(0 0, 100% 0, 100% 92%, 0 100%);
  transition: transform 0.45s ease, background 0.45s ease;
  display: block;
  /* border: 2px #e6c476a6 dashed; */
}
.data-card:nth-child(1) {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);
}

.data-card:nth-child(3) {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 93%);
}

.data-card:nth-child(4) {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 87%);
}
.wrap {
  filter: drop-shadow(
    rgba(0, 0, 0, 0.19) 0px 10px 20px,
    rgba(0, 0, 0, 0.23) 0px 6px 6px
  );
}
.data-card h3 {
  color: var(--secondary-color);
  font-size: 3em;
  font-weight: 600;
  line-height: 1;
  padding-bottom: 0.5em;
  margin: 0 0 0.142857143em;
  border-bottom: 2px solid #e5b23e;
  transition: color 0.45s ease, border 0.45s ease;
}
.data-card h4 {
  color: #627084;
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.1em;
  margin: 0 0 1.777777778em;
  transition: color 0.45s ease;
}
.data-card p {
  opacity: 0;
  color: var(--white-color);
  font-weight: 600;
  font-size: 1.1em;
  line-height: 1.1;
  margin: 0 0 1.25em;
  transform: translateY(-1em);
  transition: opacity 0.45s ease, transform 0.5s ease;
}
.data-card .link-text {
  color: #e5b23e;
  font-size: 1em;
  font-weight: 600;
  line-height: 0.9;
  margin: auto 0 0;
  transition: color 0.45s ease;
}
.link-text {
  display: none;
}
.data-card .link-text svg {
  margin-left: 0.5em;
  transition: transform 0.6s ease;
}
.data-card .link-text svg path {
  transition: fill 0.45s ease;
}
.data-card:hover,
.data-card:focus,
.data-card:active {
  background: var(--primary-color);
  transform: scale(1.02);
}
.data-card:hover h3 {
  color: var(--white-color);
  border-bottom-color: #e5b23e;
}
.data-card:hover h4 {
  color: var(--white-color);
}
.data-card:hover p {
  opacity: 1;
  transform: none;
}
.data-card:hover .link-text {
  color: var(--white-color);
}
.data-card:hover .link-text svg {
  animation: point 1.25s infinite alternate;
}
.data-card:hover .link-text svg path {
  fill: var(--white-color);
}
@keyframes point {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0.1em);
  }
}
section h2 {
  width: 100%;
  font-size: 2em;
  color: #333;
  text-align: left;
  margin-bottom: 20px;
}
.podnaslov {
  font-size: 1.5rem;
  color: #333;
  font-weight: 450;
  text-align: left;
  margin-bottom: 20px;
}
#myForm {
  background: var(--white-color);
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* border: 1px solid rgba(255, 255, 255, 0.18); */
}

#myForm input::placeholder,
#myForm select::placeholder,
#myForm input::-moz-placeholder,
#myForm select::-moz-placeholder {
  color: black;
  opacity: 1;
  font-family: "montserrat", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
}

#myForm input,
#myForm select {
  background: transparent;
  border: none;
  border-left: 1px solid #2e3c40;
  padding: 10px;
  color: black;
  flex-grow: 1;
  border-radius: 0 !important;
  height: 16px;

  font-size: 0.9rem;
}
#myForm input {
  -webkit-appearance: none;
  -moz-appearance: none;
}

#myForm input:first-child {
  border-left: 0px;
}

option {
  background: #fdfdfd70;
  color: #7e7e7e;
}

#myForm input::placeholder,
#myForm select::placeholder {
  color: black;
}
#myForm select {
  padding: 10px 20px;
  margin-right: 6px;
  height: 35px;
}

#myForm input:first-child {
  border-left: none;
}

.reserve {
  padding: 10px 20px;
  border: none;
  background: var(--primary-color);
  color: #000;
  cursor: pointer;
  /* border-radius: 5px; */
  backdrop-filter: blur(10px);
  transition: 0.3s ease;
}

#myForm a:hover {
  background: #ccc;
}

.about h2,
.pogoji h1,
.textPole h2,
.business h1,
.location h2,
.apartments h2,
.galerija h2,
.textG h2 {
  padding: 4rem 10% 2rem;
  font-size: 2.8rem;
  font-weight: 600;
  max-width: 80%;
}
.location {
  align-items: center;
  width: 100%;
  background-color: var(--primary-color);
  padding-bottom: 6rem;
  clip-path: polygon(0 0, 100% 8%, 100% 100%, 0 100%);
  background-size: cover; /* Add this line */
}
.location p,
.textPole p,
.apartments p,
.galerija p,
.textPole ul,
.business p {
  font-size: 1.4rem;
  font-weight: 400;
  margin: 0 0 2rem 10%;
  max-width: 80vw;
}
.map {
  object-fit: cover;
  /* clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 92%); */
  display: flex;
  justify-content: center;
}
iframe {
  width: 80vw;
  height: 450px;
  border: none;
  filter: grayscale(1) brightness(90%);
}
iframe:hover {
  transition: 0.25s ease-in-out;
}
.apartments-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  max-width: 100vw;
  overflow: hidden;
  flex-direction: row;
}
.apartment {
  color: var(--white-color);
  max-width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 65vw;
  background-color: var(--primary-color);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  backdrop-filter: blur(10px);
  clip-path: polygon(0 4%, 100% 0%, 100% 100%, 0 92%);
  margin: 2rem;
}

.apartment * {
  max-width: 100vw;
}

.apartment img {
  width: 350px;
  min-height: 350px;
  height: 110%;
  object-fit: cover;
}
.apartment span {
  flex-grow: 2;
}
.apartment-inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem;
  flex-grow: 1;
}
.apartment-inner h3 {
  font-size: 1.8rem;
  font-weight: 600;
  margin: 0 0 1rem;
}
.apartment-inner p {
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0 0 1rem 0.5rem;
}
.apartment-inner a {
  width: calc(100% - 2rem);
  text-decoration: none;
  color: var(--white-color);
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
  padding: 0.5rem 1rem;
  transition: 0.2s ease-in-out;
  background-color: var(--secondary-color);
  align-items: center;

  border: none;
}
.apartment-inner a:hover {
  background-color: #f9f9f98e;
  margin: 0 10%;
  width: calc(80% - 2rem);
  color: var(--secondary-color);
  /* font-size: 1.3rem; */
}
.bookNow a {
  text-decoration: none;
  color: #000000;
  font-size: 1.3rem;
  font-weight: 500;
  padding: 1rem;
  transition: 0.2s ease-in-out;
}
.fa-wifi,
.fa-tv,
.fa-utensils,
.fa-parking,
.fa-wind,
.fa-bed,
.fa-tv,
.fa-fish,
.fa-shower,
.fa-thermometer-quarter {
  font-size: 1.1rem;
  margin-right: 0.5rem;
  color: var(--white-color);
}
.apartment-info span {
  border-right: 1px solid var(--secondary-color);
  padding-right: 0.5rem;
  margin-left: 0.5rem;
}

.apartment-info img {
  height: 1.1rem;
  width: 1.1rem;
  min-height: 0px;
  filter: invert(1);
  object-fit: fill;
}
.apartment-info {
  display: inline;
  padding: 0.5rem 0;
  margin: 0 0 1rem 0;
  color: #4b4b4b;
}
.apartment:nth-child(2),
.apartment:nth-child(4),
.apartment:nth-child(6) {
  flex-direction: row-reverse;
  clip-path: polygon(0 4%, 100% 0, 100% 92%, 0 100%);
}

.apartment h3 {
  font-size: 2rem;
  font-weight: 650;
  margin: 0 0 1rem;
  color: var(--secondary-color);
  max-width: 100vw;
  overflow: hidden;
}

.apartment {
  opacity: 0;
  transform: translateX(100px);
  transition: opacity 1s, transform 1s;
}

.apartment:nth-child(odd) {
  opacity: 0;
  transform: translateX(-100px);
  transition: opacity 1s, transform 1s;
}

.apartment.visible {
  opacity: 1;
  transform: translateX(0);
}

.galerija {
  padding: 2rem 0;
  color: var(--white-color);
  background-color: var(--secondary-color);
  clip-path: polygon(0 4%, 100% 0%, 100% 92%, 0 100%);
  max-width: 100vw;
  overflow: hidden;
}
.galerija h2 {
  color: var(--white-color);
}
.galerija img {
  max-height: 70vh;
  max-width: 90vw;
  object-fit: cover;
  /* border-radius: 10px; */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  backdrop-filter: blur(10px);
}
.swiper-button-next::after,
.swiper-button-prev::after {
  color: var(--white-color);
}
.centered-slide,
.centered-slide img {
  display: flex !important;
  justify-content: center !important;
}
.textG {
  padding: 0 0 4rem;
  font-size: 1.8rem;
  font-weight: 400;
}
.swiper-pagination-bullet-active {
  background-color: var(--primary-color) !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  position: relative;
}

input[type="number"]::before,
input[type="number"]::after {
  content: "";
  position: absolute;
  right: 10px;
  width: 0;
  height: 0;
  border-style: solid;
}

input[type="number"]::before {
  border-width: 0 3px 3px 3px;
  border-color: transparent transparent #333 transparent;
  top: 10px;
}

input[type="number"]::after {
  border-width: 3px 3px 0 3px;
  border-color: #333 transparent transparent transparent;
  bottom: 10px;
}

.menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: none;
  padding: 0rem;
  width: 4rem;
  position: absolute;
  /* top: 1rem; */
  right: 1.5rem;
}
.line {
  fill: none;
  stroke: black;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideUp {
  0% {
    position: relative;
  }
  100% {
    position: fixed;
    top: calc(-100vh - 92px);
  }
}

.swiper {
  max-width: 100vw !important;
}
.textPole {
  padding: 2rem 0 6rem;
  clip-path: polygon(0 4%, 100% 0%, 100% 92%, 0 100%);
}
.textPole span {
  color: var(--primary-color);
  font-weight: 600;
}
.textPole p {
  font-weight: 450 !important;
  font-size: 1.5rem !important;
  margin: 1rem 10%;
}
.textPole ul {
  list-style: disc;
  margin: 0rem 10%;
  padding: 0 0 0 2.5rem;
  font-weight: 450;
  line-height: 1.7;
  font-size: 1.4rem !important;
  color: black;
}
input[type="date"],
input[type="time"] {
  -webkit-appearance: none;
  float: left !important;
  text-align: left !important;
  font-size: 0.9rem;
  align-items: left;
}
select {
  border-radius: 0;
}
nav {
  position: fixed;
  width: 100%;
  max-width: 100vw;
  z-index: 1;
  background-color: var(--white-color);
  /* other styles */
  padding: 1rem 0 1rem 1rem !important ;
}
.tap {
  display: block;
  position: absolute;
  z-index: 10;
  opacity: 0.6;
  top: 70%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 45px;
  height: 45px;
  animation: tap 1.35s infinite;
  transition: 0.25s ease-in-out;
}
@keyframes tap {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px) scale(1.05);
  }
  100% {
    transform: translateY(0);
  }
}
.tap img {
  width: 45px;
  height: 45px;
  display: block !important;
  transform: translate(50%, -50%);
  object-fit: cover;
}

.data-card:active .tap,
.data-card:hover .tap {
  opacity: 0;
}
.render-360 {
  /* display: flex; */
  /* padding: 2rem 0; */
  background-color: var(--white-color);
  /* clip-path: polygon(0 4%, 100% 0%, 100% 92%, 0 100%); */
  position: relative;
  box-sizing: border-box;
  height: 90vh;
  width: 100vw;
}
.render-360 canvas {
  width: 100%;
  height: 100%;
  position: relative;
  aspect-ratio: 16/9;
  clip-path: polygon(0 4%, 100% 0%, 100% 92%, 0 100%);
}
.canvas-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 70vw;
  posi.images-location .images {
    width: 100%;
    height: auto;
    gap: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: auto;
  }

  .images-location .images img {
    width: 100%;
    height: auto;
    object-fit: cover;
    grid-row: span 1;
  }
}
.icon-360 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#viewer:hover .icon-360 {
  opacity: 0;
  animation: none;
}
.room-page {
  display: flex;
  flex-direction: column;
  width: 80vw;
  padding: 0 10%;
  block-size: border-box;
  background-color: var(--primary-color);
}
.name-rate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin-top: 1rem;
}
.name-rate h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0;
  color: var(--white-color);
}
.book button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: var(--secondary-color);
  color: var(--white-color);
  border: none;
  clip-path: polygon(0 4%, 100% 0%, 100% 100%, 0 95%);
  transition: 0.25s ease-in-out;
  font-weight: 500;
  cursor: pointer;
  position: relative;
}
.book button::after {
  content: "→";
  margin-left: 1rem;
  color: var(--primary-color);
  transition: all 0.25s ease-in-out;
  transform: translateX(-50%);
}
.book button:hover {
  background-color: var(--white-color);
  transition: 0.25s ease-in-out;
  color: var(--secondary-color);
}
.book button:hover::after {
  margin-left: 1.5rem;
  color: var(--secondary-color);
}
.street {
  display: inline;
  margin-bottom: 0.5rem;
}
.name h3 {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  color: #464646;
}

.imagages-location {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 2rem 0;
  /* flex-wrap: wrap; */
}
.images-desc{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 2rem; */
  margin: 0 1rem;
  /* width: 30vw; */
  /* background-color: var(--white-color); */
  /* clip-path: polygon(0 4%, 100% 0%, 100% 100%, 0 95%); */

}
.description{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: var(--white-color);
  width: 100%;
  margin: 1rem 0;
}
.description p{
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  overflow-wrap: break-word;
  word-spacing: 5px;
}
.description p span{
  font-weight: 600;
}
.imagages-location .images {
  width: 100%; /* Adjusted to take full container width */
  height: fit-content; /* Adjusted for variable content height */
  gap: 1rem; /* Increased gap for better spacing */
  display: grid !important;
  grid-template-columns: repeat(
    auto-fit,
    minmax(250px, 1fr)
  ); 
  grid-auto-rows: auto; 
  flex-grow: 2;
  background-color: var(--white-color);
  padding: 1rem;
}

.imagages-location .images img {
  width: 100%; /* Ensure images are responsive within their cell */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Cover their cell without stretching */
  grid-row: span 1; /* Adjust if certain images need to span more rows */
}
.imagages-location .images #viewer {
  width: 100%;
  height: 100%;
  object-fit: cover;
  grid-row: span 2;
  grid-column: span 2;
  position: relative;
}
.imagages-location .images .icon-360 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  object-fit: cover;

}

.imagages-location .maps iframe {
  width: 300px;
}

.maps {
  padding: 0 0 0 1rem;
}
.imgages-location *{
  box-sizing: border-box;
}

.booking-rating{
  background-color: var(--white-color);
  width: 300px;
  margin-bottom: 1rem;
}
.rating{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border: none;
  transition: 0.25s ease-in-out;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid #aaaaaa;
}
.rating .text-{
  display: flex;
  flex-direction: column;
  align-items: start;
  border: none;
  clip-path: polygon(0 4%, 100% 0%, 100% 100%, 0 95%);
  transition: 0.25s ease-in-out;
  font-weight: 500;
  cursor: pointer;
  position: relative;

}
.rating .text- h2{
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
}
.rating .text- p{
  font-size: .8rem;
  font-weight: 400;
  margin: 0;
}
.rating span{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #003B95 ;
  border-radius: 4px 4px 4px 0;
  padding: 0.5rem;
  color: var(--white-color);
}
.comment{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin: 0 0 1rem 0;
  border: none;
  transition: 0.25s ease-in-out;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  flex-direction: column;
  border-bottom: 1px solid #aaaaaa;
}
.who{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.who span{
  padding: .5rem;
  width: 20px;
  height: 20px;
  background-color: #003B95;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
}
.who h4{
  font-size: 1rem;
  font-weight: 550;
  margin: 0;

}
.comment p{
  margin: 0 0 1rem 0;
  padding: 0 1rem;
  font-weight: 400;
}
.best-loc{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem 1rem 1rem;
  margin: 1rem 0 0;
  border: none;
  transition: 0.25s ease-in-out;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  flex-direction: row;
  border-bottom: 1px solid #aaaaaa;
}
.best-loc h2{
  font-size: 1.1rem;
  font-weight: 550;
  margin: 0;
}
.best-loc span{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #003B95 ;
  border-radius: 5px 5px 5px 0;
  padding: 0.3rem;
}
.booking-rating a{
  text-decoration: underline;
  color: #000000;
  font-size: .9rem;
  font-weight: 500;
  padding: 1rem;
  transition: 0.2s ease-in-out;
  /* width: 100%; */
  background-color: var(--white-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.booking-rating a::after {
  content: "→";
  color: #000000;
  transition: all 0.25s ease-in-out;
  transform: translateX(-50%);
  text-decoration: none !important;
}
.booking-rating a:hover{
  background-color: #ccc;
}
.booking-rating a:hover::after{
  transform: translateX(0);
}
.images canvas{
  width: 100%;
  /* height: 100%; */
  position: relative;
  aspect-ratio: 5.2/4;
  border: 1px solid #000000;
}