@media (max-width: 950px) {
    .logo img {
        height: 70px !important;
    }

    nav {
        justify-content: center !important;
    }

    .links {
        display: none !important;
        min-width: 0px !important;
        margin-left: 0rem   !important;
    }

    .menu {
        display: flex !important;
    }

    .active {
        display: flex !important;
        flex-direction: column !important;
        width: 100% !important;
        position: absolute !important;
        top: 75px !important;
        justify-content: start !important;
        left: 0 !important;
        background-color: var(--white-color) !important;
        z-index: 1 !important;
        height: calc(100vh - 92px) !important;
        animation: slideDown 0.15s ease-out !important;
        background-color: var(--primary-color) !important;
    }

    .active li {
        padding: 2rem 10% !important;
        width: 80% !important;
        text-align: center !important;
        min-width: none !important;
        animation: slideDown 0.4s ease-out !important;
    }

    .active li:hover {
        background-color: #f2f2f2 !important;
        transition: 0.25s ease-in-out !important;
    }

    .links a:hover {
        /* color: #4b4b4b; */
        font-size: 1.2rem !important;
    }

    .glass {
        width: 70% !important;
        /* min-height: 20vh; */
    }

    .text p {
        white-space: normal !important;
        padding: 0 0 0 1.5rem !important;
        font-size: 1.4rem !important;
        display: flex !important;
        justify-content: center !important;
        /* horisontally aligned */
        align-items: center !important;
    }

    .naslov {
        width: 100% !important;
        font-size: 3rem !important;
        font-weight: 600 !important;
        margin: 0 0 1rem 0 !important;
    }

    .data-card {
        width: 25vw !important;
        /* height: 30vw !important; */
        padding: 1.5rem !important;
    }
    .blog-card{
        width: clamp(300px, 80%, 600px) !important;
    }
}



@media (max-width: 1350px) {
    .data-card {
        width: 30vw !important;
        margin: 2rem !important;
        /* height: 18vw !important; */
        /* padding: 1rem !important; */
    }

    .page-contain {
        padding: 0 5% 4rem !important;
    }

    .data-card h3 {
        font-size: 2em !important;
        padding: 0em 0 0.142857143em !important;
    }

    .data-card h4 {
        font-size: .8em !important;
    }
}


@media (max-width: 1500px) {
    .apartment {
        width: 80vw !important;
    }
}

@media (max-width: 1100px) {
    .apartment {
        flex-direction: column !important;
        width: 40vw !important;
        padding-bottom: 3.5rem !important;
        min-width: 350px !important;
    }

    .apartment:nth-child(2),
    .apartment:nth-child(4),
    .apartment:nth-child(6) {
        flex-direction: column !important;
    }
    .apartImg{
        width: 100%;
      }

    .apartImg img {
        width: 100% !important;
        object-fit: cover !important;
    }

    .text {
        width: 50vw !important;
        padding: 3rem 2rem 1rem !important;
        clip-path: polygon(0 0%, 100% 4%, 100% 100%, 0 97%) !important;
        margin-top: 5vh !important;

    }

    .o-Nas {
        align-items: start !important;
    }

    .text h1 {
        font-size: 1.3rem !important;
        font-weight: 750 !important;
        margin: 0 !important;
    }

    #myForm {
        flex-direction: column !important;
        width: 100% !important;
        padding: 1rem 0 !important;
    }

    #myForm input,
    #myForm select,
    .reserve {
        width: 80% !important;
        margin: 0.5rem 0 !important;
        border: none !important;
        border-left: none !important;
        border-bottom: 1px solid var(--secondary-color) !important;
        padding: 10px !important;
    }

    .reserve {
        border: none !important;
        margin-top: 1rem !important;
    }

    #myForm select,
    .reserve {
        margin-right: 0px !important;
        margin-bottom: 0 !important;
        height: 36px !important;
        /* border-bottom: 0 !important; */
        width: calc(80% + 20px) !important;
    }

    .business {
        background: url(../Photos/iStock-1448121762.webp) no-repeat top center !important;
    }

    .business h1 {
        font-size: 1.4em !important;
        margin-bottom: 1rem !important;
        max-width: 100%     !important;
    }
    .business p{
        font-size: .9em !important;
    }
    .piecard{
        width: 45% !important;
        margin: 0 0 1.5rem !important;
        /* min-width: 195px !important; */
    }
}

@media (max-width: 800px) {
    .apartment {
        width: 80vw !important;
    }
}

@media (max-width: 550px) {
    .name-rate h1 {
        font-size: 1.8rem !important;
      }
    .data-card {
        width: 60vw !important;
        /* height: 75vw !important; */
        margin: 0.5rem !important;
        padding: 1.5rem !important;
        margin-bottom: 10vw !important;
    }
    .render-360 canvas{
        height: 80vh !important;
        width: 100% !important;
        aspect-ratio: 9/16 !important;
    }
    .data-card h3 {
        font-size: 3.5em !important;
    }

    .data-card h4 {
        font-size: 1em !important;
    }

    .data-card p {
        font-size: 1.2em !important;
    }
    .business h1{
        font-size: 1em !important;
    }
        .business p {
            font-size: .8em !important;
            padding: 0 0 0 .5rem !important;
        }
    
        .text {
            margin-top: 2rem !important;
            padding: 2rem 1rem 1rem !important;
            width: 70vw !important;
        }
        .pogoji h1, .pogoji h2, .pogoji ul{
            padding: 1rem 1rem 1rem 3vw !important;
            margin: 0 2rem !important;
        }
        .pogoji li{
            margin-bottom: 5px;
        }

    /* .business .text h1 {
        font-size: 1.4rem !important;
        padding: 0 !important;
        font-weight: 700 !important;
        max-width: 100% !important;
        flex-basis: 100% !important;
        margin: 0 0 1rem !important;
    }
    .business .text p {
        font-size: 1.2rem !important;
        margin: 0 1rem 1rem !important;
    }

    .business .text{
        display: flex !important;
        min-height: 50vh !important;
        align-content: center !important;
        padding: 3rem 1.5rem !important;
        width: 70vw !important;
        margin: 0 !important;
    } */

    .business{
        min-height: 100vh !important;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 97%) !important;
        background: url(../Photos/iStock-1448121762.webp) no-repeat center/140% !important;
    }
    .blog-card {
        margin: 2rem 1rem !important;
    }
}
@media (max-width: 950px) {
    .scroll-down-icon {
        animation: jump-phone 1.5s infinite !important;
    }
    .booking-rating{
        width: 100% !important;
        padding: .5rem;
    }
    .maps{
        padding: 0 !important;
    }
    .maps iframe{
        width: calc(100% + 1rem) !important;
        height: 50vh !important;
    }
    .imagages-location {
        display: flex;
        justify-content: space-between;
        align-items: start;
        padding: 2rem 0;
        flex-wrap: wrap;
      }
    .dashcard h3{
        font-size: .8rem !important;
    }
    .dashcard, .action .dashcard{
        width: 100% !important;
        margin: 0 0 1.5rem !important;
        min-width: 195px !important;
    }

    .piecard{
        width: 100% !important;
        margin: 0 0 1.5rem !important;
        min-width: 195px !important;
    }
    .label{
        width: 100% !important;
        justify-content: space-between !important;
        margin:  0 0 1rem !important;
    }
    .pickDate form{
        width: 100% !important;
        justify-content: space-between !important;
        flex-wrap: wrap !important;
        margin: 0 0 1rem!important;
    }
}
.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: rgb(255, 255, 255);
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
}
.loading img {
    width: 150px;
    height: 150px;
    animation: loading .3s ease-in-out infinite;
}
@keyframes loading{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
@media (min-width: 650px) {
    .rezervacija {
        display: flex;
        background: url(../Photos/piran.webp) no-repeat center/cover;
        justify-content: center;
        align-items: center;
        /* flex-direction: column; */
        min-height: calc(100vh - 92px);
        height: auto !important;
        background-color: #f5f5f5;
        padding: 2rem 4rem;
        background-color: var(--primary-color) !important;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 97%) !important;
        backdrop-filter: blur(10px); /* Add this line */
    }
    .rezervacija form{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100% !important;
    }
    .rezervacija h1{
        width: 60% !important;
        font-size: 1.7rem !important;
    }
    .rezervacija span{
        min-width: 40%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        background-color: var(--white-color) !important;
        padding: 4rem !important;
        clip-path: polygon(0 0, 100% 4%, 100% 95%, 0 100%) !important;
    }
    
}