.config form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    /* width: 60%;
    margin: 0 auto; */
    padding: 0 2rem;
}
.config *{
    box-sizing: border-box;
}
.config label{
    font-size: 1.2rem;
    /* margin: 10px; */
    width: 100%;
}
.config input[type="text"]{
    width: 100%;
    padding: 10px;
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.config input[type="submit"]{
    width: 100%;
    padding: 10px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--primary-color);
    cursor: pointer;
    transition: 0.15s ease-in-out;
}
.config input[type="date"]{
    width: 100%;
    padding: 10px;
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;

}
.config select{
    width: 100%;
    padding: 10px;
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.config input[type="submit"]:hover{
    background-color: var(--secondary-color);
    color: #fff;
}
.config{
    width: 35vw;
}
.generator{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}
.display{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-grow: 1;
    background-color: #fff;
}